import { useDYWindowStore } from "~/modules/nuxt3-module-doyac/stores/window"

export function useYouTubeUtility() {
  const enableIframeApi = (iframe: HTMLIFrameElement) => {
    if (!iframe) {
      document.querySelectorAll("iframe[src='youtube.com/embed']").forEach(() => {
        _enableIframeApi(iframe)
      })
    } else {
      document.querySelectorAll("iframe[src='nmv.naver.com']").forEach(() => {
        _enableIframeApi(iframe)
      })
    }
  }

  const _enableIframeApi = (iframe: HTMLIFrameElement) => {
    try {
      const srcAttr = iframe.src
      if (srcAttr) {
        const src = new URLSearchParams(srcAttr)
        src.append('enablejsapi', '1')

        iframe.contentWindow?.location.replace(src.toString());
      }
    } catch (e) {
      console.error(e);
    }
  }

  const fitSize = (iframe: HTMLIFrameElement, ratio?: number, maxHeight?: number) => {
    const windowStore = useDYWindowStore() // 혹시 스토어 통째로 받아서 써야 하나?

    if (!ratio) ratio = .5625;
    if (!maxHeight) maxHeight = 450;

    const parent = iframe.parentElement ? _parentTillNotInline(iframe.parentElement) : null

    let width = parent ? Math.floor(Number(parent.clientWidth)) : 0 // 일부 기종에선 소수점까지 구해버려서 아래식에 의해 아이프레임 안에 있는 값이 -10되는 문제가 생김

    if (width > windowStore.windowWidth) width = windowStore.windowWidth - 10;
    if (width === 0) width = 200;

    let height = width * ratio;
    if (height > windowStore.windowHeight) height = windowStore.windowHeight - 20;

    iframe.style.width = width + 'px'
    iframe.style.height = height + 'px'
  }

  const invokeFunction = (iframe: HTMLIFrameElement, func: () => any, args: any) => {
    const src = iframe.src
    if (src) {
      if (src.indexOf('youtube.com/embed') != -1) {
        iframe.contentWindow?.postMessage(JSON.stringify({
          'event': 'command',
          'func': func,
          'args': args || []
        }), "*");
      }
    }
  }

  const invokeFunctionToAll = (func: () => any, args: any) => {
    const iframes: NodeListOf<HTMLIFrameElement> = document.querySelectorAll('iframe');
    iframes.forEach((iframe) => {
      invokeFunction(iframe, func, args)
    })
  }

  const _parentTillNotInline = (elm: HTMLElement): HTMLElement => {
    // `elm.style.display` 값은 스타일을 지정했을 때만 존재하며 별도로 지정하지 않은 기본 상태라면 빈 문자열이 된다.
    // 따라서 단순히 `elm.style.display`만 따지면 제대로 된 부모 영역을 못찾으므로 `span` 태그인지도 따져봐야 한다.
    return elm.style.display.toLowerCase() === 'inline' || elm.tagName.toLowerCase() === 'span' ? (elm.parentElement ? _parentTillNotInline(elm.parentElement) : elm) : elm
  }

  return {
    enableIframeApi, fitSize, invokeFunction, invokeFunctionToAll
  }
}